import React from 'react'
import tw from 'twin.macro'
import { Container, PrimaryContent } from '../layout'
import { H4 } from '../typography'
import { usePageLayoutDataContext } from './index'
import { cssMq } from '../mediaquery'

const SectionHeaderNew = ({ subtitle, title, listMode, hideListNumber }) => {
  const { navData = [] } = usePageLayoutDataContext()
  const index = navData.findIndex(e => e.title === title)
  const { href, sectionHeaderRef: ref } = navData[index]

  if (listMode) {
    return (
      <>
        <div
          id={href}
          ref={ref}
          className="mb-4"
          css={cssMq({
            paddingTop: subtitle ? '75px' : '0',
            marginTop: subtitle ? '-30px' : '0',
            pageBreakInside: 'avoid',
          })}
        >
          <PrimaryContent
            className="pt-2 w-full flex flex-col items-center mx-auto"
            style={{ maxWidth: '100%', fontFamily: '"acumin-pro", sans' }}
          >
            <div
              className={`flex w-full items-center ${
                subtitle ? 'mb-3' : 'mb-1'
              }`}
            >
              {hideListNumber ? (
                <div
                  className="relative flex justify-center w-full border-t border-t-av-gray-7"
                  style={{ height: '21px' }}
                ></div>
              ) : subtitle ? (
                <div
                  className="relative flex justify-center w-full border-t border-t-av-gray-7"
                  style={{ height: '21px' }}
                >
                  <div
                    className="absolute flex justify-center bg-white"
                    style={{ top: '-21px', height: '42px', width: '62px' }}
                  >
                    <div
                      className="flex items-center justify-center rounded-full bg-av-gray-7 text-white text-2xl pb-1"
                      style={{ height: '100%', width: '42px' }}
                    >
                      {subtitle}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <H4 className="font-black text-av-gray-6 text-2xl lg:text-4xl leading-none text-center">
              {title}
            </H4>
          </PrimaryContent>
        </div>
      </>
    )
  }
  return (
    <>
      {index !== 0 && <div className="lg:h-12" />}
      <div
        id={href}
        ref={ref}
        className="mb-4 lg:mb-10"
        css={cssMq({
          paddingTop: ['50px', '50px', '60px', '60px', '60px'],
          marginTop: ['-50px', '-50px', '-60px', '-60px', '-60px'],
          pageBreakInside: 'avoid',
        })}
      >
        <PrimaryContent
          className="border-t border-solid border-black pt-2 w-full mx-auto"
          css={{ maxWidth: '100%', fontFamily: '"acumin-pro", sans' }}
        >
          <div
            className="tk-acumin-pro text-av-purple text-xs lg:text-lg"
            css={{ weight: 400 }}
          >
            {subtitle || title}
          </div>
          {subtitle && (
            <H4 className="font-black text-av-gray-6 text-2xl lg:text-4xl leading-none">
              {title}
            </H4>
          )}
        </PrimaryContent>
      </div>
    </>
  )
}

export default SectionHeaderNew
